<script>
import { nextTick } from 'vue'

export default {
  name: 'rec-media-audio-bar',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'text-primary',
    },
  },
  computed: {
    progressBar() {
      return (this.progress / this.duration) * 100
    },
    progressCircleColor() {
      return 'bg-' + this.color.split('-')[1]
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp)
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    onMouseMove(e) {
      if (!this.drag) return

      const rect = e.target.getBoundingClientRect()
      const x = e.clientX - rect.left - 5

      this.$emit('set-progress', (x / rect.width) * 100)
    },
    onMouseDown(e) {
      this.drag = true
      nextTick(() => {
        this.onMouseMove(e)
      })
    },
    onMouseUp() {
      this.drag = false
    },
  },
}
</script>

<template>
  <div
    ref="bar"
    class="relative h-[5px] cursor-pointer rounded-lg bg-strong"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
  >
    <div
      class="absolute left-0 top-0 h-[5px] rounded-l-lg"
      :class="{
        [progressCircleColor]: true,
      }"
      :style="{ width: `${progressBar}%`, 'pointer-events': 'none' }"
    />
    <div
      class="absolute left-0 top-0 -mt-[5px] h-[15px] w-[15px] rounded-full"
      :class="{
        [progressCircleColor]: true,
      }"
      :style="{ left: `${progressBar}%`, 'pointer-events': 'none' }"
    />
  </div>
</template>
